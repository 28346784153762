<template>
  <vs-card id="audiences-view">
    <div slot="header">
      <vs-row vs-align="center">
        <vs-col vs-w="8">
          <h3>{{ lang.audiences.view.title[languageSelected] }}</h3>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-w="4" v-if="isAuthAux('konecta.broadcast.add', true)">
          <!-- <vs-button icon="add" :to="{ path: '/diffusion/audiences/add' }">
            {{ lang.audiences.view.addAudience[languageSelected] }}
          </vs-button>-->

          <vs-button @click="openAddAudience">
            {{ lang.audiences.view.addAudience[languageSelected] }}
          </vs-button>

          <AddAudience ref="addAudiencePopup" @onSuccess="loadAudiences" />
        </vs-col>
      </vs-row>
    </div>
    <div v-if="loaded">
      <!-- <DiffusionHeader v-if="hasAudiences" /> -->
      <EmptyTable
        v-if="!hasAudiences && isAuthAux('konecta.broadcast.add', true)"
        :text="lang.audiences.view.table.empty[languageSelected]"
        :buttonText="lang.audiences.view.addAudience[languageSelected]"
        :button-action="openAddAudience"
      />
      <DiffusionTable
        v-if="hasAudiences"
        :audiences="audiences"
        :max-items="maxItems"
        :on-edit="onAudienceEdit"
        :on-delete="alertAudienceDelete"
        :on-download="onAudienceDownload"
      />
    </div>
  </vs-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

import downloadCsv from '@/utils/downloadCsv.js'

import staffManagementMixin from '@/mixins/staffManagementMixin'

export default {
  name: 'Diffusion',
  components: {
    // DiffusionHeader: () => import('./components/DiffusionHeader.vue'),
    DiffusionTable: () => import('./DiffusionTable.vue'),
    AddAudience: () => import('./AddAudience.vue'),
    EmptyTable: () => import('../../components/EmptyTable.vue')
  },
  mixins: [staffManagementMixin],
  data() {
    return {
      downloadCsv,
      maxItems: 15,
      loaded: false
    }
  },
  computed: {
    ...mapState(['languageSelected', 'lang', 'session', 'useStaffManagement']),
    ...mapGetters('audiences', ['audiences']),
    hasAudiences() {
      return this.audiences.length > 0
    }
  },
  methods: {
    ...mapActions('audiences', [
      'getAudiences',
      'deleteAudience',
      'downloadAudience'
    ]),
    async loadAudiences() {
      this.$vs.loading()
      try {
        await this.getAudiences()
      } catch (error) {
        console.warn(error)
      } finally {
        this.loaded = true
        this.$vs.loading.close()
      }
    },
    onAudienceEdit(audienceId) {
      console.warn(audienceId)
    },
    async onAudienceDownload({ id, name, platform }) {
      this.$vs.loading()
      try {
        const response = await this.downloadAudience(id)

        let filename = name
        filename += '_'
        filename += platform
        filename += '_'
        filename += new Date().toISOString()

        const fields = ['name', 'number']
        this.downloadCsv(
          filename,
          fields,
          response.data.map(item => ({
            name: item.fullname,
            number: item.senderId
          }))
        )
      } catch (error) {
        this.$vs.notify({
          title: this.lang.audiences.view.table.downloadAudience.title[
            this.languageSelected
          ],
          text: this.lang.audiences.view.table.downloadAudience.text[
            this.languageSelected
          ],
          color: 'danger',
          position: 'top-right'
        })
      } finally {
        this.$vs.loading.close()
      }
    },
    alertAudienceDelete(audienceId) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: this.lang.audiences.delete.confirm.title[this.languageSelected],
        text: this.lang.audiences.delete.confirm.text[this.languageSelected],
        acceptText: this.lang.botMaker.accept[this.languageSelected],
        cancelText: this.lang.botMaker.cancel[this.languageSelected],
        accept: async () => this.onAudienceDelete(audienceId)
      })
    },
    async onAudienceDelete(audienceId) {
      this.$vs.loading()
      try {
        await this.deleteAudience(audienceId)
        await this.getAudiences()
        this.$vs.notify({
          title: this.lang.audiences.add.wizard.messages.save.success.title[
            this.languageSelected
          ],
          text: this.lang.audiences.add.wizard.messages.save.success.text[
            this.languageSelected
          ],
          color: 'success',
          position: 'top-right'
        })
      } catch (error) {
        this.$vs.notify({
          title: this.lang.audiences.add.wizard.messages.save.error.title[
            this.languageSelected
          ],
          text: this.lang.audiences.add.wizard.messages.save.error.text[
            this.languageSelected
          ],
          color: 'danger',
          position: 'top-right'
        })
      } finally {
        this.$vs.loading.close()
      }
    },
    openAddAudience() {
      this.$refs.addAudiencePopup.openPopup()
    }
  },
  mounted() {
    this.loadAudiences()
  }
}
</script>
